
import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Container, Card } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const RequestOtp = () => {
  const [otp, setOtp] = useState(Array(6).fill('')); // OTP input state
  const [timer, setTimer] = useState(30); // 30 seconds timer
  const [isOtpDisabled, setIsOtpDisabled] = useState(false); // Disable OTP fields after timer
  const navigate = useNavigate();
  const location = useLocation();

  // Get email from location state
  const email = location.state?.email;

  // Static OTP data for demo purposes
  const staticOtp = '123456';

  // Handle OTP input change
  const handleOtpChange = (e, index) => {
    let value = e.target.value;
    if (/[^0-9]/.test(value)) return; // Only allow numbers
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically focus on the next input field when current one is filled
    if (value && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

 // Handle OTP verification
const handleSubmit = (e) => {
    e.preventDefault();
    const otpString = otp.join('');
    console.log('Entered OTP: ', otpString);
    if (otpString === staticOtp) {
      toast.success('OTP Verified Successfully!'); // Show success toast
      // Delay navigation to show the toast first
      setTimeout(() => {
        navigate('/createnewpass'); // Navigate to the /createnewpass route
      }, 1000); // Delay for 2 seconds (2000 milliseconds)
    } else {
      toast.error('Invalid OTP. Please try again.'); // Show error toast
    }
  };
  // Timer effect to count down
  useEffect(() => {
    if (timer === 0) {
      setIsOtpDisabled(false); // Enable OTP fields once timer ends
      return;
    }

    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(timerInterval); // Cleanup interval on component unmount
  }, [timer]);

  // Handle resend OTP action after timer ends
  const handleResendOtp = () => {
    setTimer(30); // Reset timer
    setIsOtpDisabled(true); // Disable OTP fields during new timer countdown
  };
  const handleBackToLogin = () => {
    // Navigate back to the login page
    navigate('/login');
  };

  return (
    <div  style={{ background: "linear-gradient(135deg, #ec9283, #f5ae7c)" }}>
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <Row>
        <Col lg={12} md={6} sm={12}>
          <Card className="p-4 shadow-sm border-0">
            <div className="text-center">
              <h2>Check your email</h2>
              <p>We sent a password reset link to {email}</p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Row className="justify-content-center">
                {otp.map((digit, index) => (
                  <Col key={index} xs={2}>
                    <Form.Control
                      id={`otp-input-${index}`}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleOtpChange(e, index)}
                      className="otp-input"
                      style={{
                        fontSize: '20px',
                        textAlign: 'center',
                        padding: '5px',
                        margin: '5px',
                        width: '50px',
                      }}
                      disabled={isOtpDisabled} // Disable input based on the timer
                    />
                  </Col>
                ))}
              </Row>
              <div className="d-flex justify-content-center">
                <Button variant="primary" type="submit" className="mt-3" 
                disabled={isOtpDisabled}
                style={{backgroundColor:'#F6581F',border:'none'}}>
                  Verify OTP
                </Button>
              </div>
            </Form>

            <div className="text-center mt-1">
              <p>{timer > 0 ? `Time remaining: ${timer}s` : 'Time expired'}</p>
              {timer === 0 && (
                <Button variant="link" onClick={handleResendOtp}>
                  Resend OTP
                </Button>
              )}
            </div>

            <Button variant="link" onClick={handleBackToLogin} className="mt-1">
            &#8592; Back to log in
            </Button>
          </Card>
        </Col>
      </Row>
      {/* Toast container to show toast messages */}
      <ToastContainer 
        position="top-center"
        autoClose={5000} 
        hideProgressBar={true}
        closeButton={false}
        pauseOnHover={true}
        draggable={false}
        limit={3}
      />
    </Container>
    </div>
  );
};

export default RequestOtp;
