// import React, { useState } from 'react';
// import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
// import { BsFillShieldLockFill } from 'react-icons/bs';
// import { useNavigate } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const CreateNewPass = () => {
//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const navigate = useNavigate();

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (password === confirmPassword) {
//       toast.success('Password created successfully!', {
//         position: "top-center",
//         autoClose: 2000,
//         onClose: () => navigate('/passwordreset') // Navigate after toast
//       });
//     } else {
//       toast.error('Passwords do not match!', {
//         position: "top-center",
//         autoClose: 2000,
//       });
//     }
//   };

//   const handleBackToLogin = () => {
//     navigate('/admin');
//   };

//   return (
//     <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
//       <ToastContainer />
//       <Row>
//         <Col lg={12} md={6} sm={12}>
//           <Card className="p-4 shadow-sm border-0">
//             <div className="text-center mb-4">
//               <BsFillShieldLockFill style={{ fontSize: '40px', color: '#6c63ff' }} />
//               <h3>Create New Password</h3>
//               <p>Choose a new password to secure your account.</p>
//             </div>
//             <Form onSubmit={handleSubmit}>
//               <Form.Group controlId="formPassword" className="mb-3">
//                 <Form.Label className="fw-bold">Password</Form.Label>
//                 <Form.Control
//                   type="password"
//                   placeholder="Enter new password"
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                   required
//                 />
//               </Form.Group>
//               <Form.Group controlId="formConfirmPassword" className="mb-3">
//                 <Form.Label className="fw-bold">Confirm Password</Form.Label>
//                 <Form.Control
//                   type="password"
//                   placeholder="Confirm your password"
//                   value={confirmPassword}
//                   onChange={(e) => setConfirmPassword(e.target.value)}
//                   required
//                 />
//               </Form.Group>
//               <div className="d-flex justify-content-center">
//                 <Button variant="primary" type="submit">
//                   Submit
//                 </Button>
//               </div>
//             </Form>
//             <div className="mt-3 text-center">
//               <Button variant="link" onClick={handleBackToLogin}>
//                  &#8592; Back to log in
//               </Button>
//             </div>
//           </Card>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default CreateNewPass;






import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { BsFillShieldLockFill, BsEye, BsEyeSlash } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateNewPass = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Toggle for password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Toggle for confirm password visibility
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      toast.success('Password created successfully!', {
        position: "top-center",
        autoClose: 2000,
        onClose: () => navigate('/passwordreset') // Navigate after toast
      });
    } else {
      toast.error('Passwords do not match!', {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };

  const handleBackToLogin = () => {
    navigate('/login');
  };

  return (
  <div  style={{ background: "linear-gradient(135deg, #ec9283, #f5ae7c)" }}>
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <ToastContainer />
      <Row>
        <Col lg={12} md={6} sm={12}>
          <Card className="p-4 shadow-sm border-0">
            <div className="text-center mb-4">
              <BsFillShieldLockFill style={{ fontSize: '40px', color: '#FFB30F' }} />
              <h3>Create New Password</h3>
              <p>Choose a new password to secure your account.</p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label className="fw-bold">Password</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Enter new password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="flex-grow-1"
                  />
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '10px',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                    }}
                  >
                    {showPassword ? <BsEyeSlash size={15} /> : <BsEye size={15} />}
                  </span>
                </div>
              </Form.Group>
             
              <Form.Group controlId="formConfirmPassword" className="mb-3">
                <Form.Label className="fw-bold">Confirm Password</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirm your password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    className="flex-grow-1"
                  />
                  <span
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '10px',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                    }}
                  >
                    {showConfirmPassword ? <BsEyeSlash size={15} /> : <BsEye size={15} />}
                  </span>
                </div>
              </Form.Group>
              <div className="d-flex justify-content-center">
                <Button variant="primary" type="submit"
                style={{backgroundColor:'#F6581F',border:'none'}}>
                  Submit
                </Button>
              </div>
            </Form>
            <div className="mt-3 text-center">
              <Button variant="link" onClick={handleBackToLogin}>
                &#8592; Back to log in
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default CreateNewPass;
