import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { FaUser, FaEnvelope, FaPhone, FaMapMarkerAlt, FaCalendarAlt } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import Footer from "./Footer";
import {  useNavigate } from 'react-router-dom';
import '../profileCss/home1.css';

const Profile = () => {
    const navigate = useNavigate();
    const [userDtl, setUserDtl] = useState(JSON.parse(localStorage.getItem('user')));

       // Function to format date from yyyy-mm-dd to dd-mm-yyyy
       const formatDate = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');  // Add leading zero
        const month = String(d.getMonth() + 1).padStart(2, '0');  // Months are zero-based
        const year = d.getFullYear();
        return `${day}-${month}-${year}`;
      };



  return (
    <>
     <div className='vh-100' style={{backgroundColor:'rgb(245, 249, 255)'}}>
    <Container  className="mt-3">
    <div className="back-button mb-3" onClick={() => navigate(-1)}>
        <IoArrowBack /> 
      </div>
  <div>
      <Col xs={12} sm={10} md={8} lg={12} xl={12}>
      <div className="formbg p-3  shadow-sm" >
      <h2 style={{ textAlign: 'center' }}>Profile </h2>
      <Form>
        <Row className="mb-2">
          <Col xs={12} md={6}>
            {/* Name */}
            <Form.Group controlId="staticName" className="mb-2">
              <Form.Label style={{ fontWeight: "bold" }}>Name</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FaUser />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  value={userDtl.customerName}
                  // placeholder="John Doe"
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            {/* Email */}
            <Form.Group controlId="staticEmail" className="mb-2">
              <Form.Label style={{ fontWeight: "bold" }}>Email</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FaEnvelope />
                </InputGroup.Text>
                <Form.Control
                  type="email"
                 value={userDtl.customerEmail}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col xs={12} md={6}>
            {/* Mobile */}
            <Form.Group controlId="staticMobile" className="mb-2">
              <Form.Label style={{ fontWeight: "bold" }}>Mobile</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FaPhone />
                </InputGroup.Text>
                <Form.Control
                  type="tel"
                  value={userDtl.customerMobNo}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            {/* Date of Birth */}
            <Form.Group controlId="staticDob" className="mb-2">
              <Form.Label style={{ fontWeight: "bold" }}>Date of Birth</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FaCalendarAlt />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  value={formatDate(userDtl.customerDob)}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
         
          <Col xs={12} md={6}>
            {/* Address */}
            <Form.Group controlId="staticAddress" className="mb-2">
              <Form.Label style={{ fontWeight: "bold" }}>Address</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FaMapMarkerAlt />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  value={userDtl.customerAddress}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      </div>
      </Col>
      </div>
    </Container>
    <Footer/>
    </div>
    </>
  );
};

export default Profile;
