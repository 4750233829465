import React, { useState, useEffect } from 'react';
import { Table, Button, Row, Col, Container } from 'react-bootstrap';
import { IoArrowBack } from "react-icons/io5";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from "../BaseURL";
import '../profileCss/services.css';
import Footer from './Footer';

const Services = () => {
  const [services, setServices] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const customerUuid = location.state?.customerUuid;  // Get customerUuid from location.state

  useEffect(() => {
    if (customerUuid) {
      fetchServices(customerUuid);  // Pass customerUuid to the API request
    }
  }, [customerUuid]);

  const fetchServices = async (customerUuid) => {
    try {
      const response = await axios.get(`${BASE_URL}/vehicleservice/getCustVSById/${customerUuid}`);
      setServices(response.data.data);
      setTotalCost(response.data.totalCost);
    } catch (error) {
      console.error("Error fetching services:", error);
      alert("Failed to load services.");
    }
  };

  return (
    <>
    <div className='vh-100' style={{backgroundColor:'rgb(245, 249, 255)'}}>
      
    <Container className="mt-3">
      <div className="back-button mb-3" onClick={() => navigate(-1)}>
        <IoArrowBack /> 
      </div>

       <Col xs={12} sm={10} md={12} lg={12} xl={12}>
          <div className="tablebg p-1 shadow-sm">
              <h2 className="text-center my-1">All Services</h2>
      <Table bordered hover responsive>
        <thead >
          <tr >
            <th>Sr.</th>
            {/* <th>Customer</th> */}
            <th>Service</th>
            <th>Vehicle </th>
            <th>Issued Dt</th>
             <th>Exired Dt</th>
            <th>Cost</th>
           
          </tr>
        </thead>
        <tbody>
          {services.length > 0 ? (
            services.map((service, index) => (
              <tr key={service.id}>
                <td>{index + 1}</td>
                {/* <td>{service.customerName}</td> */}
                <td>{service.serviceType}</td>
                <td>{service.vehicleType}</td>
                <td>{new Date(service.issuedDate).toLocaleDateString('en-GB')}</td>
                  <td>{new Date(service.expiredDate).toLocaleDateString('en-GB')}</td>
                <td>{service.serviceCost}</td>
                
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center">No services found.</td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="text-end">
        <strong>Total Cost: ₹{totalCost}</strong>
      </div>
    </div>
    </Col>
    </Container>
    <Footer/>
    </div>
    </>
  );
};
export default Services