import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';  // For navigation to next page
import '../profileCss/firstpage.css';
// import logo from '../IVN_PNG.png';
import { BASE_URL } from "../BaseURL";
import axios from "axios";


function Loading() {
    const [loaderImage, setLoaderImage] = useState(null);
    const [loading, setLoading] = useState(true);
   
    // Fetch loader image from the server on component mount
 
    const fetchLoaderImage = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/sitesettings/getloaderimg`);
          if (response.data.loaderImage) {
            setLoaderImage(response.data.loaderImage); // Update state with loader image URL
          }
        } catch (error) {
          console.error('Error fetching loader image:', error);
        }
      };
  
     // Fetch loader image when the component mounts
     useEffect(() => {
      fetchLoaderImage();
    }, []);

    return (
        <>
        <div className={`loader-container ${loading ? 'show' : 'hide'}`}>
            <div className="logo-container"
            data-aos="custom-fade">
                <img  src={`${BASE_URL}/${loaderImage}`}  
                alt="Logo" 
                className="logo" />
            </div>
           
            
        </div>
        </>
    );
}

export default Loading;
