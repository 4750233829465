import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap'; // Removed Button as it's not used
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import '../profileCss/secondpage.css'; // Import the new CSS file for styling
import '../profileCss/firstpage.css';
import { FaArrowRight } from 'react-icons/fa';

const Information = () => {
    const navigate = useNavigate(); // Initialize the navigate function

    // Function to handle navigation to the next page
    const goToNextPage = () => {
        navigate('/login'); // Replace '/next-page' with the actual route of your next page
    };

    return (
        <>
        <div className='information-container '>
        <Container>
        <div className="page-container">
            {/* Header Section */}
            <Row className="heading">
                <Col className="text-center">
                    <h1>Welcome to I Vehicle Notify</h1>
                </Col>
            </Row>

            {/* Intro Section */}
            <Row className="intro">
                <Col className="text-center">
                    <p className="intro-text">
                        Our platform makes it easy for vehicle owners to take care of all their vehicle-related needs in one place.
                        We offer a simple, fast, and reliable way to manage everything, so you don’t have to worry about complicated processes.
                        Our goal is to help you save time and make managing your vehicle easier.
                    </p>
                </Col>
            </Row>

            {/* Benefits Section */}
            <Row className="benefits text-center">
                <Col xs={12}>
                    <h2>Why Choose Us?</h2>
                </Col>
            </Row>

            {/* Row for Benefit Cards */}
            <Row className="justify-content-center">
    {/* Benefit Cards */}
    {[
        { icon: "⏱", title: "Time-saving" },
        { icon: "⚙️", title: "Efficiency" },
        { icon: "📲", title: "Convenience" },
        { icon: "✔️", title: "Compliance" },
        { icon: "💰", title: "Cost-effective" },
        { icon: "🔒", title: "Security" },
    ].map((benefit, index) => (
        <Col xs={12} sm={6} md={4} lg={2} className="d-flex justify-content-center mb-3" key={index}>
            <Card className="benefit-card">
                <Card.Body>
                    <div className="benefit-icon">{benefit.icon}</div>
                    <Card.Title className="benefit-title">{benefit.title}</Card.Title>
                </Card.Body>
            </Card>
        </Col>
    ))}
</Row>

            {/* Navigation Button */}
            <div className="continue-container" onClick={goToNextPage}>
                <span className="continue-text">Continue</span>
                <div className="arrow-container">
                    <FaArrowRight className="arrow" />
                </div>
            </div>
        </div>
        </Container>
        </div>
        </>
    );
};

export default Information;
