
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../profileCss/login.css";
// import img1 from "../IVN_PNG.png";
import { BASE_URL } from "../BaseURL";
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false); // Manage password visibility
    const [loaderImage, setLoaderImage] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // API call to login endpoint
            const response = await axios.post(`${BASE_URL}/customer/loginCustomer`, {
                customerEmail: username,
                customerPassword: password,
            });

            // Handle success response
            if (response.data.token) {

                // Show success toast
                toast.success("Login successful!", { position: "top-center", autoClose: 2000 });

                // Save token and user details to localStorage
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("user", JSON.stringify(response.data.customer));

                // Navigate to home page after 2 seconds
                setTimeout(() => {
                    window.location.href = "/home";
                }, 2000);
            }
        } catch (error) {
            // Handle error response
            const errorMessage = error.response?.data?.message || "Login failed. Please try again.";

            // Show error toast
            if (errorMessage === "Customer not found.") {
                toast.error("Customer not found!", { position: "top-center", autoClose: 3000 });
            } else if (errorMessage === "Invalid credentials.") {
                toast.error("Invalid credentials. Please try again.", {
                    position: "top-center",
                    autoClose: 3000,
                });
            } else {
                toast.error(errorMessage, { position: "top-center", autoClose: 3000 });
            }
        }
    };

    // Fetch loader image from the server on component mount

    const fetchLoaderImage = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/sitesettings/getloaderimg`);
            if (response.data.loaderImage) {
                setLoaderImage(response.data.loaderImage); // Update state with loader image URL
            }
        } catch (error) {
            console.error('Error fetching loader image:', error);
        }
    };

    // Fetch loader image when the component mounts
    useEffect(() => {
        fetchLoaderImage();
    }, []);

    const handleForgotPasswordClick = () => {
        navigate('/forgetpass');
      };

    return (
        <div className="login-page">
            {/* Background Circles */}
            <div className="circle-1"></div>
            <div className="circle-2"></div>
            <div className="circle-3"></div>

            {/* Main Container */}
            <Container fluid className="h-100">
                <Row className="h-100 align-items-center">
                    {/* Login Form Section */}
                    <Col className="d-flex justify-content-center align-items-center">
                        <div className="form-container">
                            <img
                                src={`${BASE_URL}/${loaderImage}`}
                                alt="Login Illustration"
                                className="welcome-image"
                            />
                            <Form onSubmit={handleSubmit} className="login-form">
                                <Form.Group controlId="username" className="form-group">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        placeholder="Enter your username"
                                    />
                                </Form.Group>

                                <Form.Group controlId="password" className="form-group">
                                    <Form.Label>Password</Form.Label>
                                    <div className="password-container ">
                                        <Form.Control
                                            type={passwordVisible ? "text" : "password"}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Enter your password"
                                        />
                                        <span
                                            className="password-toggle "
                                            onClick={() => setPasswordVisible(!passwordVisible)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {passwordVisible ? <BsEyeSlash /> : <BsEye />}
                                        </span>
                                    </div>
                                </Form.Group>
                                <div className="d-flex justify-content-end">
                                    <span onClick={handleForgotPasswordClick} style={{ cursor: 'pointer' }}>
                                        Forget Password?
                                    </span>
                                </div>

                                <Button type="submit" className="login-btn mt-2">
                                    Login
                                </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Toast Container */}
            <ToastContainer />
        </div>
    );
};

export default LoginPage;
