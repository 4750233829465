
import React, { useState } from 'react';
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BsFillShieldLockFill } from "react-icons/bs";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleRequestOTP = () => {
    if (!email) {
      setErrorMessage('Please enter your email address.');
      return;
    }
    setErrorMessage(''); // Clear the error message if email is valid
    // Navigate to the OTP request page with the email as state
    navigate('/reqotp', { state: { email } });
  };

  const handleBackToLogin = () => {
    // Navigate back to the login page
    navigate('/login');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle password reset logic here
    alert(`Password reset instructions sent to: ${email}`);
  };

  return (
    <div  style={{ background: "linear-gradient(135deg, #ec9283, #f5ae7c)" }}>
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <Row>
        <Col lg={12} md={6} sm={12}>
          <Card className="p-4 shadow-sm border-0">
            <div className="text-center mb-4">
              <BsFillShieldLockFill style={{ fontSize: '40px', color: '#FFB30F' }} />
              <h3>Forgot password?</h3>
              <p>No worries, we’ll send you reset instructions.</p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label className="fw-bold">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                 {errorMessage && <small className="text-danger">{errorMessage}</small>}
              </Form.Group>
              <div className="d-flex justify-content-center">
                <Button variant="primary" type="button" onClick={handleRequestOTP}
                style={{backgroundColor:'#F6581F',border:'none'}}>
                  Request For OTP
                </Button>
              </div>
            </Form>
            <div className="mt-3 text-center">
              <Button variant="link" onClick={handleBackToLogin}>
                &#8592; Back to log in
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default ForgotPassword;
