import React from 'react'
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FiCheckCircle } from "react-icons/fi";

export default function PasswordReset() {
      const navigate = useNavigate();
      const handleBackToLogin = () => {
        // Navigate back to the login page
        navigate('/login');
      };

  return (
   <>
    <div  style={{ background: "linear-gradient(135deg, #ec9283, #f5ae7c)" }}>
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
         <Row>
           <Col lg={12} md={6} sm={12}>
             <Card className="p-4 shadow-sm border-0">
               <div className="text-center mb-4">
                 <FiCheckCircle style={{ fontSize: '40px', color: '#FFB30F' }} />
                 <h3> Password Reset</h3>
                 <p>Your Password has been successfully reset.
                  <br />  Click below to login in magically.
                 </p>
               </div>
              
                 <div className="d-flex justify-content-center">
                   <Button variant="primary" type="button" onClick={handleBackToLogin} 
                   style={{backgroundColor:'#F6581F',border:'none'}}>
                     Continue
                   </Button>
                 </div>
              
               <div className="mt-3 text-center">
                 <Button variant="link" onClick={handleBackToLogin}>
                 &#8592; Back to log in
                 </Button>
               </div>
             </Card>
           </Col>
         </Row>
       </Container>
       </div>
   </>
  )
}
