import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './FrontPages/HomePage';
// import Header from './FrontPages/Header'; // Make sure you import Header and Footer components
import Footer from './FrontPages/Footer'; // Make sure you import Header and Footer components
import Loading from './FrontPages/Loading';
import Information from './FrontPages/Information';
import LoginPage from './FrontPages/LoginPage';
import './App.css';
import Services from './FrontPages/Services';
import Profile from './FrontPages/Profile';
import RequestOtp from './FrontPages/RequestOtp';
import CreateNewPass from './FrontPages/CreateNewpass';
import PasswordReset from './FrontPages/PasswordReset';
import ForgetPassword from './FrontPages/ForgetPass';

function App() {

  const [isLoading, setIsLoading] = useState(true); // State to control loading screen

  // Use effect to simulate loading process
  useEffect(() => {
      const timer = setTimeout(() => {
          setIsLoading(false); // Set to false after 3 seconds to show Information page
      }, 3000); // Duration of loading screen, adjust as needed

      return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  return (
    <Router> {/* Wrap your app in BrowserRouter */}
      <div className="app-container">
        {/* Conditionally render Header and Footer */}
        <Routes>
          {/* If FirstPage, don't render Header and Footer */}
          <Route path="/" element={isLoading ? <Loading /> : <Information />} />
          <Route path="/information" element={<Information />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/services" element={<Services />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/forgetpass"  element={<ForgetPassword />} />
          <Route path="/reqotp"  element={<RequestOtp/>} />
          <Route path="/createnewpass"  element={<CreateNewPass/>} />
          <Route path="/passwordreset"  element={<PasswordReset/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
