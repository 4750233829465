import React, { useEffect, useState } from 'react';
import { BsPersonCircle } from 'react-icons/bs';
import { FaSearch } from 'react-icons/fa';
import { InputGroup, FormControl } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../profileCss/header.css';
import { MdOutlineLogout } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [customerName, setCustomerName] = useState("Customer");
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve customer name from localStorage
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.customerName) {
      setCustomerName(user.customerName);
    }
  }, []); // Run only once on component mount

  const handleLogout = () => {
    localStorage.clear(); // Clear all items in localStorage
    navigate('/login'); // Redirect to the home page
  };

  return (
    <header className="d-flex justify-content-between align-items-center p-3 header">
      {/* Greeting - Hi Admin */}
       <div className="header-title">
        <h2>{`Hi, ${customerName}`}</h2>
      </div>

      {/* Search Bar and Person Icon */}
      <div className="d-flex align-items-center">
      <button
    className="btn btn-sm"
    style={{ backgroundColor: '#FFB30F', color: 'white' }}
    onClick={handleLogout}
  >
    <MdOutlineLogout />
    Logout
  </button>
        {/* Search Bar */}
        {/* <InputGroup className="me-3 search-input-group">
          <InputGroup.Text className="bg-white">
            <FaSearch size={13} color="#6c757d" />
          </InputGroup.Text>
          <FormControl
            placeholder="Search for..."
            aria-label="Search"
            className="search-input"
          />
        </InputGroup> */}

        {/* Person Icon */}
        {/* <BsPersonCircle size={33} /> */}
      </div>
    </header>
  );
};

export default Header;
