// import React from 'react';
// import { Link } from 'react-router-dom';
// import { AiFillHome } from 'react-icons/ai';
// import { MdLocalOffer, MdAccountCircle } from 'react-icons/md';
// import { FaExchangeAlt } from 'react-icons/fa';
// import '../profileCss/footer.css';
// import { useNavigate } from 'react-router-dom';

// const Footer = () => {
//   const navigate = useNavigate();
//   const [customerId, setCustomerId] = useState("Customer");

//   return (
//     <footer className="footer">
//       <div className="tab">
//         <Link to="/home">
//           <AiFillHome size={27} />
//           <span>Home</span>
//         </Link>
//       </div>
//       <div className="tab">
//         <Link to="/services">
//           <MdLocalOffer size={27} />
//           <span>Services</span>
//         </Link>
//       </div>
//       <div className="tab">
//         <Link to="/transaction">
//           <FaExchangeAlt size={27} />
//           <span>Transaction</span>
//         </Link>
//       </div>
//       <div className="tab">
//         <Link to="/profile">
//           <MdAccountCircle size={27} />
//           <span>Profile</span>
//         </Link>
//       </div>
//     </footer>
//   );
// };

// export default Footer;






import React, { useEffect, useState } from 'react';
import { AiFillHome } from 'react-icons/ai';
import { MdLocalOffer, MdAccountCircle } from 'react-icons/md';
import { FaExchangeAlt } from 'react-icons/fa';
import '../profileCss/footer.css';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  
  const navigate = useNavigate();
  const [customerId, setCustomerId] = useState("Customer");

  useEffect(() => {
    // Retrieve customer name from localStorage
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.customerId) {
      setCustomerId(user.customerId);
    }
  }, []); 

  return (
    <footer className="footer">
      <div className="tab" onClick={() => navigate('/home')}>
        <AiFillHome size={27} />
        <span>Home</span>
      </div>
      <div className="tab" onClick={() => navigate(`/services`, { state: { customerUuid: customerId } })}>
        <MdLocalOffer size={27} />
        <span>Services</span>
      </div>
      {/* <div className="tab" onClick={() => navigate('/transaction')}>
        <FaExchangeAlt size={27} />
        <span>Transaction</span>
      </div> */}
      <div className="tab" onClick={() => navigate('/profile')}>
        <MdAccountCircle size={27} />
        <span>Profile</span>
      </div>
    </footer>
  );
};

export default Footer;
