
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaCarSide, FaCalculator } from 'react-icons/fa'; // Import relevant icons
import { IoCardOutline } from "react-icons/io5";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../profileCss/home1.css';
import img1 from '../CarSecurityImage.png';
import Header from './Header';
import Footer from './Footer';

const HomePage = () => {
  const navigate = useNavigate();
  const [customerId, setCustomerId] = useState("Customer");

  useEffect(() => {
    // Retrieve customer name from localStorage
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.customerId) {
      setCustomerId(user.customerId);
    }
  }, []); 

  // Data for the services with descriptions
  const serviceData = [
    { id: 1, icon: <FaCarSide size={50} />, title: "Vehicle Services", description: "Improve your fitness with our programs.", link: '/services' },
  
  ];

  // Handle service card click and navigate

  const handleCardClick = () => {
    navigate(`/services`, { state: { customerUuid: customerId } });
  };

  return (
    <>
    <Header/>
    <Container fluid className="home-page" style={{backgroundColor:'rgb(245, 249, 255)'}}>
      {/* Image Section */}
      <Row className="justify-content-center mt-5 ">
        <Col xs={12} md={8} lg={6} className="text-center">          
            {/* Image before Cards */}
            <div className="image-container text-center mt-5">
              <img
                src={img1} // Use the imported image here
                alt="Descriptive Image"
                className="img-fluid"

              />
            </div>
        </Col>
      </Row>

      {/* Services Section */}
      <Row className="card-container mt-5">
        {serviceData.map((service) => (
          <Col xs={12} sm={6} md={4} lg={3} key={service.id} className="d-flex justify-content-center">
            <Card
              className="custom-card text-center"
              onClick={handleCardClick} 
            >
              <Card.Body>
                <div className="icon-container">{service.icon}</div>
                <Card.Title className="card-title">{service.title}</Card.Title>
                <Card.Text className="card-text">{service.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
     
    </Container>
    <Footer/>
    </>
  );
};

export default HomePage;
